import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

export default function useCustomersList() {
  // Use toast
  const toast = useToast()

  const refCustomerListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'clienteId',
      label: i18n.t('AppCustomer.columns.customerId'),
      sortable: true,
      sortKey: 'ClienteId',
    },
    {
      key: 'alias',
      label: i18n.t('AppCustomer.columns.nickname'),
      sortable: true,
      sortKey: 'Alias',
    },
    {
      key: 'nombre',
      label: i18n.t('AppCustomer.columns.name'),
      sortable: true,
      sortKey: 'Nombre',
    },
    {
      key: 'rfc',
      label: i18n.t('AppCustomer.columns.rfc'),
      sortable: true,
      sortKey: 'Rfc',
    },
    {
      key: 'domicilio',
      label: i18n.t('AppCustomer.columns.address'),
      sortable: true,
      sortkey: 'Domicilio',
    },
    {
      label: i18n.t('Lists.Actions'),
      key: 'actions',
      sortable: true,
      sortKey: 'ClienteId',
    },
  ]
  const perPage = ref(10)
  const totalCustomers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('clienteId')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCustomerListTable.value ? refCustomerListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCustomers.value,
    }
  })

  const refetchData = () => {
    refCustomerListTable.value.refresh()
  }

  const sortCustomerIdAsc = () => {
    isSortDirDesc.value = false
    sortBy.value = 'clienteId'
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCusomers = (ctx, callback) => {
    store
      .dispatch('app-customers/fetchCustomers', {
        searchQuery: searchQuery.value,
        // alias: searchQuery.value,
        // nombre: searchQuery.value,
        // rfc: searchQuery.value,
        // domicilio: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
      })
      .then(response => {
        const { clientes, total } = response.data
        callback(clientes)
        totalCustomers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('AppCustomer.fetchError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    fetchCusomers,
    tableColumns,
    perPage,
    currentPage,
    totalCustomers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCustomerListTable,
    refetchData,
    sortCustomerIdAsc,
  }
}
